<template>
  <div class="login_outer" :style="{ backgroundImage: 'url(' + userBgLogo + ')' }">
    <div class="login_content">
      <router-view></router-view>
    </div>
    <Footer class="login_footer"></Footer>
  </div>
</template>

<script>
import Footer from '../Layout/Footer';
import userBgLogo from '../assets/login/bg_login.png';
import { setHash, setCompany } from '../utils/common';
export default {
  components: { Footer },

  data() {
    return {
      userBgLogo: userBgLogo
    };
  },

  mounted() {
    const { company } = this.$route.params;
    if (company) {
      setHash(company);
      setCompany(company);
    }
  }
};
</script>

<style lang="less" scoped>
.login_outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  .login_content {
    flex: 2;
  }
  .login_footer {
    color: white;
  }
}
</style>
